import { REDIRECT_V1_URLS } from '../envs'

export const ellipsisInTheEndOfString = (string: string, size: number): string => {
  if (string?.length >= size) {
    return string?.substring(0, size) + '...'
  }
  return string
}

export const removeLastCharacter = (string: string, character: string): string => {
  const lastCharacter = string.substr(string.length - 1)
  if (lastCharacter === character) {
    return string.slice(0, -1)
  }

  return string
}

export const removeCountryCodeFromPhoneNumber = (phoneNumber: string): string => {
  const firstCharacter = phoneNumber.substr(0, 1)

  if (firstCharacter === '+') {
    return phoneNumber.substr(3)
  }

  return phoneNumber
}

export const getRedirectUrl = () => {
  const urls = REDIRECT_V1_URLS?.replace(' ', '').split(',')

  const hostname = window.location.hostname

  const domain = getUpperLevelDomain(hostname)

  return urls?.find((url) => url?.includes(domain))
}

export const getUpperLevelDomain = (host: string) => {
  if (host?.includes('cadastro-restrict')) {
    return '-restrict.antecipafacil.com.br'
  }

  const countFullStop = host?.split('.').length - 1

  if (countFullStop >= 3) {
    const parts = host.split('.')
    parts.shift()
    return parts.join('.')
  }

  return host
}

export const getDomain = (host: string) => {
  if (host?.includes('.com.br')) {
    const parts = host.split('.')

    const unformattedDomain = parts.slice(-3)

    return unformattedDomain?.join('.')
  }

  return host
}

export const getValuesInsideParentheses = (text: string) => {
  const regExp = /\(([^)]+)\)/
  const matches = regExp.exec(text)

  return matches ? matches[1] : text
}

export const cnpjMask = (mask: string): string =>
  mask?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')

export const removeAllLettersAndSpecialCharacters = (string: string): string => {
  return string?.replace(/[^0-9]+/g, '')
}

export const phoneMask = (mask: string | undefined) => {
  mask = mask?.replace(/\D/g, '')

  if (mask?.length === 11) {
    mask = mask?.replace(/^(\d\d)(\d)/g, '($1) $2')
    mask = mask?.replace(/(\d{5})(\d)/, '$1-$2')
  } else {
    mask = mask?.replace(/^(\d\d)(\d)/g, '($1) $2')
    mask = mask?.replace(/(\d{4})(\d)/, '$1-$2')
  }

  return mask
}

export const cellphoneMask = (mask: string | undefined) => {
  mask = mask?.replace(/\D/g, '')
  mask = mask?.replace(/^(\d\d)(\d)/g, '($1) $2')
  mask = mask?.replace(/(\d{5})(\d)/, '$1-$2')
  return mask
}

export const removeCurrencyFromString = (string: string) => {
  return string?.replace('R$', '').trim()
}

export const removeAllNonNumericCharactersFromString = (string: string, shouldRemoveThousandSeparator = false) => {
  return string.replace(/\D/g, (match) => {
    if (match === ',') {
      return '.'
    } else if (match === '.') {
      return shouldRemoveThousandSeparator ? '' : '.'
    } else {
      return ''
    }
  })
}
