import React, { useState } from 'react'
import styled from 'styled-components'
import CompanyWelcomeForm from '../../../companies/CompanyWelcomeForm'
import { CircularProgress } from '@mui/material'
import FileCard from '../../../cards/FileCard'
import PrimaryButton from '../../../buttons/PrimaryButton'
import CrudCreateModal from '../../CrudCreateModal'
import AlertModal from '../../../modal/AlertModal'

interface IDocumentGroup {
  title: string
  documentTypes: IDocumentType[]
}

export interface IDocumentType {
  tagName: string
  tagColor: string
  name: string
  key: string
  statusId?: number
}

export interface IPrimaryFileSplitCrudData {
  basePath: string
  presentation: {
    title: string
    description: string[]
  }
  listDocumentGroup: IDocumentGroup[]
  isLoading?: boolean
  submit?: () => void
  isSubmitting?: boolean
  closeCreateModal?: () => void
  openModal?: boolean
  isDisabled?: boolean
}

interface IProps {
  className?: string
  data: IPrimaryFileSplitCrudData
}

const List: React.FC<IProps> = ({ className, data }: IProps) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)

  const checkDocumentsStatus = () => {
    const requiredDocumentsNames = [
      'Documentos de Constituição',
      'Comprovante de endereço da sede',
      'Declaração de faturamento',
      'Documento de identificação',
      'Comprovante de endereço'
    ]

    const allDocuments = data?.listDocumentGroup?.flatMap((group) => group.documentTypes)

    const hasAllRequiredDocuments = requiredDocumentsNames.every((requiredDocName) => {
      const doc = allDocuments.find((d) => d.name === requiredDocName)

      return doc && ['Aprovado', 'Em Análise'].includes(doc.tagName)
    })

    return hasAllRequiredDocuments
  }

  const handleConfirmSubmit = () => {
    setIsConfirmModalOpen(false)

    const documentsValid = checkDocumentsStatus()

    if (documentsValid) {
      setIsSuccessModalOpen(true)
      data.submit?.()
    } else {
      setIsErrorModalOpen(true)
    }
  }

  return (
    <div className={className}>
      {data?.isLoading ? (
        <div className="list__loading">
          <CircularProgress size={32} data-testid="test-circular-progress" />
        </div>
      ) : (
        <>
          {data?.closeCreateModal && data?.openModal && (
            <CrudCreateModal open={!!data?.openModal} onClose={data?.closeCreateModal} />
          )}
          <CompanyWelcomeForm header={data?.presentation?.title} descriptionText={data?.presentation?.description} />
          <div className="list__card--wrapper">
            {data?.submit && (
              <div className="list__button--container">
                <div className="list_button--wrapper">
                  <PrimaryButton
                    onClick={() => setIsConfirmModalOpen(true)}
                    isLoading={!!data?.isSubmitting}
                    disabled={!!data?.isDisabled}
                  >
                    Enviar cadastro para análise
                  </PrimaryButton>
                </div>
              </div>
            )}
            {data?.listDocumentGroup?.map((documentGroup) => (
              <div className="list__column--wrapper" key={documentGroup?.title}>
                <h5 data-testid="primary-file-split-crud-list-title-test">{documentGroup?.title}:</h5>
                {documentGroup?.documentTypes?.map((documentType) => (
                  <FileCard
                    key={documentType?.name}
                    title={documentType?.name}
                    tagName={documentType?.tagName}
                    tagColor={documentType?.tagColor}
                    redirectTo={`${data?.basePath}/form/${documentType?.key}`}
                  />
                ))}
              </div>
            ))}
          </div>
        </>
      )}
      <AlertModal
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        type="warning"
        texts={{
          title: 'Já completou seu cadastro?',
          description: (
            <span>
              Antes de enviar o cadastro para análise, certifique-se de que já preencheu todas as{' '}
              <span style={{ fontWeight: 'bold' }}>informações obrigatórias</span>, clique em{' '}
              <span style={{ fontWeight: 'bold' }}>“Detalhes” </span> para saber quais são.
              <br />
              Lembre-se que depois do envio não será possível adicionar ou editar as informações.
            </span>
          )
        }}
        buttons={[
          {
            label: 'Detalhes',
            onClick: () => setIsDetailsModalOpen(true),
            isPrimary: false
          },
          {
            label: 'Não',
            onClick: () => setIsConfirmModalOpen(false),
            isPrimary: false
          },
          {
            label: 'Sim',
            onClick: handleConfirmSubmit,
            isPrimary: true,
            isSubmit: true
          }
        ]}
      />
      <AlertModal
        open={isDetailsModalOpen}
        onClose={() => setIsDetailsModalOpen(false)}
        type="warning"
        texts={{
          title: 'Informações obrigatórias',
          description: (
            <div>
              <p style={{ fontSize: '18px' }}>É necessário preencher:</p>
              <ul style={{ marginLeft: '20px' }}>
                <li>Dados da empresa</li>
                <li>Dados de todos os representantes</li>
                <li>Dados bancários</li>
              </ul>
              <p style={{ fontSize: '18px', marginTop: '10px' }}>É necessário anexar os seguintes documentos:</p>
              <ul style={{ marginLeft: '20px' }}>
                <li>Documento de constituição</li>
                <li>Comprovante de endereço da sede</li>
                <li>Declaração simplificada da junta comercial</li>
                <li>Declaração de faturamento</li>
                <li>Documento de identificação de todos os representantes</li>
                <li>Comprovante de endereço de todos os representantes</li>
              </ul>
            </div>
          )
        }}
        buttons={[
          {
            label: 'Voltar',
            onClick: () => setIsDetailsModalOpen(false),
            isPrimary: true
          }
        ]}
      />
      <AlertModal
        open={isErrorModalOpen}
        onClose={() => setIsErrorModalOpen(false)}
        type="error"
        texts={{
          title: 'Cadastro incompleto',
          description: 'Preencha todos os dados e anexe os documentos obrigatórios para enviar o cadastro para análise.'
        }}
        buttons={[
          {
            label: 'Fechar',
            onClick: () => setIsErrorModalOpen(false),
            isPrimary: true
          }
        ]}
      />
      <AlertModal
        open={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        type="success"
        texts={{
          title: 'Cadastro enviado para análise!',
          description:
            'Aguarde enquanto analisamos sua documentação. Entraremos em contato em breve para te instruir sobre as próximas etapas.'
        }}
        buttons={[
          {
            label: 'Fechar',
            onClick: () => setIsSuccessModalOpen(false),
            isPrimary: true
          }
        ]}
      />
    </div>
  )
}

export default styled(List)`
  min-height: calc(100vh - 240px);

  .list__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 240px);
  }

  .list__card--wrapper {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 60px;

    .list__column--wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;

      h5 {
        font-size: 16px;
        font-weight: bold;
        color: ${({ theme: { colors } }) => colors?.tertiaryFont};
      }
    }
  }

  .list__button--container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  @media (max-width: 900px) {
    .list__card--wrapper {
      gap: 16px;

      .list__column--wrapper {
        width: 100%;
      }
    }

    .list__button--container {
      width: 100%;

      .list_button--wrapper {
        width: 100%;
      }
    }
  }
`
