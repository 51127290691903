import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useContext } from 'react'
import { OnboardingContext } from '../../states/OnboardingContext'
import Loading from '../../components/Loading'

const AnalyzingCompany: React.FC = () => {
  const { id } = useParams()

  const navigate = useNavigate()
  const { getCompanyProposalById } = useContext(OnboardingContext)

  useEffect(() => {
    if (!id || id === 'undefined') return navigate('/')
  }, [id, navigate])

  useEffect(() => {
    if (id) {
      const func = setInterval(() => getCompanyProposalById(id), 10 * 1000)

      return () => clearInterval(func)
    }
  }, [id, getCompanyProposalById])

  return <Loading />
}

export default AnalyzingCompany
